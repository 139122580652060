import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import React, { useState } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import { darkTheme } from './themes/dark';
import { lightTheme } from './themes/light';
import { muiDarkTheme, muiLightTheme } from './themes/mui';

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const muiTheme = isDarkMode ? muiDarkTheme : muiLightTheme;

  const handleThemeChange = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <StyledThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Router>
          <Navbar
            isDarkMode={isDarkMode}
            handleThemeChange={handleThemeChange}
          />
          <div
            id="app_cotainer"
            style={{ textAlign: 'center', padding: '0 15px' }}
          >
            <Routes>
              <Route path="/" element={<Home isDarkMode={isDarkMode} />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </Router>
      </MuiThemeProvider>
    </StyledThemeProvider>
  );
};

export default App;
