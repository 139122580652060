import { Container as MuiContainer, Typography } from '@mui/material';
import styled, { keyframes } from 'styled-components';

const bounceEffect = keyframes`
  0%, 100% {
    transform: scale(1);  // Start and end at normal scale
  }
  50% {
    transform: scale(1.05);  // Scale up to 105% in the middle of the animation
  }
  75% {
    transform: scale(1.03);  // Scales back down to 103% for a bounce effect
  }
`;

// Styled-components for the homepage
export const Container = styled(MuiContainer)`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.onBackground};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
`;

export const Heading = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  margin-top: 40px;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    max-width: 90%;
  }

  /* Soft box-shadow for blending effect */
  box-shadow: 0 0 -30px -30px rgba(255, 251, 255, 0.8);

  mask-border-mode: alpha;
`;

export const Description = styled(Typography)`
  && {
    margin: 20px auto; // Increase bottom margin
  }
`;

export const DownloadButtons = styled.div`
  display: flex;
  gap: 30px; /* Increase space between the store logos */
  justify-content: center; /* Center-align the download buttons */

  @media (max-width: 600px) {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
    gap: 20px; /* Reduce gap on mobile */
  }
`;

export const DownloadImage = styled.img`
  width: 150px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out; // Transition for other transformations, if necessary
  &:hover {
    animation: ${bounceEffect} 0.6s ease-in-out; // Apply the bounce effect on hover
  }
`;
