import { NightsStay, WbSunny } from '@mui/icons-material';
import React from 'react';
import {
  StyledAppBar,
  StyledIconButton,
  StyledQuickreplyIcon,
  StyledToolbar,
  StyledTypography,
} from './Navbar.styles';

const Navbar = ({ isDarkMode, handleThemeChange }) => {
  return (
    <StyledAppBar>
      <StyledToolbar>
        {/* Styled QuickreplyIcon */}
        <StyledQuickreplyIcon />

        {/* Styled Typography */}
        <StyledTypography variant="h6" noWrap component="a" href="/ ">
          SHOKO AI
        </StyledTypography>

        {/* Day/Night Icon based on theme, click to toggle */}
        <StyledIconButton
          onClick={handleThemeChange}
          color="inherit"
          aria-label={
            isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'
          }
        >
          {isDarkMode ? <NightsStay /> : <WbSunny />}
        </StyledIconButton>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default Navbar;
