import QuickreplyIcon from '@mui/icons-material/Quickreply';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import styled from 'styled-components';

// Styled-components for Material UI components
export const StyledAppBar = styled(AppBar)`
  position: static;
`;

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;

export const StyledTypography = styled(Typography)`
  font-weight: 700;
  letter-spacing: 0.3rem;
  color: inherit;
  text-decoration: none;
  flex-grow: 1;

  // Hide on small screens, show on medium and larger screens
  @media (max-width: 960px) {
    display: none;
  }
`;

export const StyledIconButton = styled(IconButton)`
  color: inherit;
`;

export const StyledQuickreplyIcon = styled(QuickreplyIcon)`
  margin-right: 1rem;

  // Hide on small screens, show on medium and larger screens
  @media (max-width: 960px) {
    display: none;
  }
`;
