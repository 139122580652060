export const darkTheme = {
  colors: {
    primary: 'rgb(220, 184, 255)',
    onPrimary: 'rgb(71, 12, 122)',
    primaryContainer: 'rgb(95, 43, 146)',
    onPrimaryContainer: 'rgb(240, 219, 255)',
    secondary: 'rgb(208, 193, 218)',
    onSecondary: 'rgb(54, 44, 63)',
    secondaryContainer: 'rgb(77, 67, 87)',
    onSecondaryContainer: 'rgb(237, 221, 246)',
    tertiary: 'rgb(243, 183, 190)',
    onTertiary: 'rgb(75, 37, 43)',
    background: 'rgb(29, 27, 30)',
    onBackground: 'rgb(231, 225, 229)',
    surface: 'rgb(29, 27, 30)',
    onSurface: 'rgb(231, 225, 229)',
  },
};
