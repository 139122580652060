import React from 'react';
import appleStoreDark from '../../assets/images/apple-store-dark.svg';
import appleStoreLight from '../../assets/images/apple-store-light.svg';
import googlePlayDark from '../../assets/images/google-play-dark.svg';
import googlePlayLight from '../../assets/images/google-play-light.svg';
import logoDark from '../../assets/images/logo-dark_bg.png';
import logoLight from '../../assets/images/logo-light_bg.png';
import {
  Container,
  Description,
  DownloadButtons,
  DownloadImage,
  Logo,
} from './Home.styles.js';

const Home = ({ isDarkMode }) => {
  return (
    <Container>
      {/* Logo Image */}
      <Logo src={isDarkMode ? logoDark : logoLight} alt="Shoko AI Logo" />
      <Description>
        Shoko AI is here to provide personalized motivational messages, tailored
        for your emotions and needs. Now the whole world can receive Shoko&#39;s
        advice and find inspiration every day.
      </Description>
      <Description>Coming soon to Google Play and Apple App Store.</Description>

      {/* Store Logos: switch between light and dark theme logos */}
      <DownloadButtons>
        <DownloadImage
          src={isDarkMode ? googlePlayDark : googlePlayLight}
          alt="Google Play Store"
        />
        <DownloadImage
          src={isDarkMode ? appleStoreDark : appleStoreLight}
          alt="Apple App Store"
        />
      </DownloadButtons>
    </Container>
  );
};

export default Home;
